import React from 'react';

const Legend = () => {
  return (
    <div className='legend-table'>
      <div className='legend-table__wrapper'>
        <span className='legend-table__text social-media'>Social Media</span>
        <span className='legend-table__text serious-work'>Serious Work</span>
        <span className='legend-table__text personal-development'>Personal Development</span>
        <span className='legend-table__text fun-stuff'>Fun Stuff</span>
      </div>
    </div>
  );
};

export default Legend;
