import React, { useEffect, useState } from 'react';
import useHttp from '../../hooks/use-http';
import Legend from './Legend';

import PeriodicElement from './PeriodicElement';

const PeriodicTable = () => {
  const [table, setTable] = useState({ data: [] });

  const { isLoading, error, sendRequest } = useHttp();

  useEffect(() => {
    sendRequest(
      {
        url: 'https://api.shrestharitesh.com/api/tables',
      },
      setTable
    );
  }, [sendRequest]);

  const tableContent = table.data.map((item, index) => {
    return (
      <PeriodicElement
        key={index}
        title={item.title}
        sub_title={item.sub_title}
        description={item.description}
        type={item.type}
        link={item.link}
      />
    );
  });

  if (error) {
    return <p>error</p>;
  }

  return (
    <main class='container mx-auto mt-20'>
      <h1>My Daily Periodic Table</h1>
      <h5>How I waste my time on the internet</h5>
      <div className='periodic-table'>
        <div className='empty-spacer-0'></div>
        <div className='empty-spacer-1'></div>
        <div className='empty-spacer-2'></div>
        {isLoading ? 'Loading...' : tableContent}
      </div>
      <Legend />
    </main>
  );
};

export default PeriodicTable;
