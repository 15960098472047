import React from 'react';

const PeriodicElement = (props) => {
  return props.link ? (
    <a
      href={props.link}
      className={`periodic-element ${props.type.slug}`}
      data-description={props.description}
      rel='noreferrer'
      target='_blank'
    >
      <div className='periodic-element-inner'>
        <div className='sub-title'>{props.sub_title}</div>
        <div className='title'>{props.title}</div>
      </div>
    </a>
  ) : (
    <div className={`periodic-element ${props.type.slug}`} data-description={props.description}>
      <div className='periodic-element-inner'>
        <div className='sub-title'>{props.sub_title}</div>
        <div className='title'>{props.title}</div>
      </div>
    </div>
  );
};

export default PeriodicElement;
